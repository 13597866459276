import React from 'react'
import './contact.css'
import {MdEmail} from 'react-icons/md'
import {RiMessengerLine} from 'react-icons/ri'
import {BsWhatsapp} from 'react-icons/bs'
import { useRef} from 'react'
import emailjs from 'emailjs-com'


const Contact = () => {
  const form = useRef ();

  const sendEmail = (e) => {
    e.preventDefault();
    
    emailjs.sendForm('service_axsq4gi', 'template_idrsafu', form.current, 'uLcFcz34EOvcCEhZy')

    e.target.reset()
  };
  return (
    <section id='contact'>
      <h5>Get in Touch</h5>
      <h2>Contact Me</h2>

      <div className="container contact__container">
        <div className="contact__options">
        <article className="contact__option">
          <MdEmail className='contact__option-icon' />
          <h4>Email</h4>
          <h5>jon.d90@gmail.com</h5>
          <a href="mailto:jon.d90@gmail.com" target='_blank'>Send a message</a>
        </article>

        <article className="contact__option">
          <RiMessengerLine className='contact__option-icon' />
          <h4>Facebook</h4>
          <h5>Aljon Docena</h5>
          <a href="https://m.me/aljond1" target='_blank'>Send a message</a>
        </article>

        <article className="contact__option">
          <BsWhatsapp className='contact__option-icon' />
          <h4>Whatsapp</h4>
          <h5>+639393222162</h5>
          <a href="https://api.whatsapp.com/send?phone=+639393222162" target='_blank'>Send a message</a>
        </article>
        </div>
        {/* End of contact options */}
        <form ref={form} onSubmit={sendEmail} >
          <input type="text" name='name' placeholder='Your Full Name' required />
          <input type="email" name='email' placeholder='Your Email' required />
          <textarea name="message" placeholder='Your Message' rows="7" required></textarea>
          <button type='submit' className='btn btn-primary'>Send Message</button>
        </form>
      </div>
    </section>
  )
}

export default Contact