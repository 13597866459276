import React from 'react'
import {BsLinkedin} from 'react-icons/bs'
import {FaGithub} from 'react-icons/fa'
import {FaFacebookSquare} from 'react-icons/fa'

const HeaderSocials = () => {
  return (
    <div className='header__socials'>
      <a href="https://linkedin.com/in/jon-d-72992ad" target='_blank'><BsLinkedin /></a> 
      <a href="https://github.com/ceprogrammer69" target='_blank'><FaGithub /></a> 
      <a href="https://web.facebook.com/aljond1" target='_blank'><FaFacebookSquare /></a> 
    </div>
  )
}

export default HeaderSocials