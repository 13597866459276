import React from 'react'
import './about.css'
import ME from '../../assets/me-about.jpg'
import {FaAward} from 'react-icons/fa'
import {FiUsers} from 'react-icons/fi'
import {VscLibrary} from 'react-icons/vsc'


const About = () => {
  return (
    <section id='about'>
      <h5>Get To Know</h5>
      <h2>About Me</h2>

      <div className="container about__container">
        <div className="about__me">
          <div className="about__me-image">
            <img src={ME} alt="About Image" />
          </div>
        </div>

        <div className="about__content">
          <div className="about__cards">
            <article className='about__card'>
              <FaAward  className='about__icon'/>
              <h5>Experience</h5>
              <small>4+ Years Working</small>
            </article>

            <article className='about__card'>
              <FiUsers  className='about__icon'/>
              <h5>Clients</h5>
              <small>Freelancing</small>
            </article>

            <article className='about__card'>
              <VscLibrary  className='about__icon'/>
              <h5>Projects</h5>
              <small>3 Major Projects</small>
            </article>
          </div>

          <p>
          Motivated civil engineer with strong passion in
          learning, seeking to transition into a career in web
          development. Proficient in HTML5, BOOTSTRAP CSS
          and JavaScript. Experience with React, Node.js, PHP
          / MySQL, Laravel Framework and Git. Skilled in
          project management, problem-solving, and
          technical analysis. Strong attention to detail and
          ability to work collaboratively with a team.
          </p>
          <a href="#contact" className='btn btn-primary'>Let's Talk</a>
          
        </div>
      </div>
    </section>
  )
}

export default About